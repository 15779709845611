import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { Button, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NewWindow, postMsgToPeNote } from '../Common/home-common';
import Popup from 'reactjs-popup';
import { ConfigContext } from '../../components/Common/ConfigHelper';
import { GlobalContext } from '../../contexts/GlobalContext';
import { useBetSlipMsgQuery } from '../Home/Data/SitecoreDataHooks';
import { glassboxCustomEvent } from '../Common/utils/glassbox';
import { getWebIDHashed } from '../Common/utils/wa';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { clearLoginSessionStorage } from './LoginIBHooks';
import Loading from '../Common/ui-components/Loading';
import useServerTime from '../Common/hooks/useServerTime';

const CustomScrollbars = (props) => (
    <div className="tandCTb" id="tandCTb">
        <Scrollbars style={{ height: '100%' }} autoHeight={false} {...props} />
    </div>
);

export const LoginMenu = ({ loginKey, calcTimeOutLogout, handleClickMenuItem, isReadOnly, isSpeedbet }) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const [menuInfo, setMenuInfo] = useState({});
    const { betslipMenu } = useContext(ConfigContext);
    const globalContext = useContext(GlobalContext);

    useEffect(() => {
        if (betslipMenu) {
            setMenuInfo(betslipMenu);
        }
    }, [betslipMenu]);

    const menuItem = (arr) => {
        return arr.map((_item, _index) => {
            let cTemp = lang === 'en' ? _item.nE : _item.nC;
            if (!(_item.hide && sessionStorage.getItem('webTV') != 'true')) {
                return (
                    <Dropdown.Item
                        eventKey={_index}
                        onClick={() => handleClickMenuItem(_item)}
                        value={cTemp}
                        key={`${_item.nE}`}
                    >
                        {cTemp}
                    </Dropdown.Item>
                );
            }
        });
    };


    const LogoutMenu =  useCallback(({menuItem, loginKey, menuInfo, lang, handleClickMenuItem, isReadOnly, isSpeedbet}) => {
        let curMenuData = [];
        if (loginKey) {
            if (!menuInfo?.loginMenu) {
                return <div></div>;
            }
            curMenuData = menuInfo.loginMenu;
        } else {
            if (!menuInfo?.logoutMenu) {
                return <div></div>;
            }
            curMenuData = menuInfo.logoutMenu;
        }
        let i = 0;
        return curMenuData.map((item, index) => {
            const isEnLang = lang === 'en';
            const labelName = isEnLang ? item.nE : item.nC;
            const labelName2 = isEnLang ? item.nE2 : item.nC2;

            if (!(isReadOnly && item.nE.includes('Register Online'))) {
                if (item.child) {
                    return (
                        <div
                            className={`cursor-point logoutDropBtn ${loginKey ? 'loginDropBtn' : ''}`}
                            key={`logoutMenu-${i++}`}
                        >
                            <DropdownButton
                                as={ButtonGroup}
                                key={`DropdownButton-${i++}`}
                                id={`dropdown-variants-${i++}`}
                                variant={labelName.toLowerCase()}
                                title={
                                    <div className={`text-align-left ${isSpeedbet ? 'menu-txt pcard' : 'menu-txt'}`}>
                                        <span>
                                            {labelName}
                                            {isEnLang && <>&nbsp;</> }
                                        </span>
                                        <span>
                                            {labelName2}
                                        </span>
                                    </div>
                                }
                                align="end"
                            >
                                {menuItem(item.child)}
                            </DropdownButton>
                        </div>
                    );
                } else {
                    return (
                        <div
                            className="menu-txt cursor-point"
                            key={`logoutMenu-${i++}`}
                            onClick={() => {
                                handleClickMenuItem(item);
                            }}
                        >
                            <span>
                                {labelName}
                                {isEnLang && <>&nbsp;</> }
                            </span>
                            <span>
                                {labelName2}
                            </span>
                        </div>
                    );
                }
            }
        });
    },[]);

    const SettingMenu = useCallback(({menuItem, loginKey, menuInfo, lang, handleClickMenuItem}) => {
        let curMenuData = [];
        if (loginKey) {
            if (!menuInfo?.loginSetting) {
                return <div></div>;
            }
            curMenuData = menuInfo.loginSetting;
        } else {
            if (!menuInfo?.logoutSetting) {
                return <div></div>;
            }
            curMenuData = menuInfo.logoutSetting;
        }
        let i = 0;
        return curMenuData.map((item, index) => {
            const labelName = lang === 'en' ? item.nE : item.nC;

            if (item.child) {
                return (
                    <div
                        className={`cursor-point logoutDropBtn ${loginKey ? 'loginDropBtn' : ''}`}
                        key={`logoutMenu-${i++}`}
                    >
                        <DropdownButton
                            as={ButtonGroup}
                            key={`DropdownButton-${i++}`}
                            id={`dropdown-variants-${i++}`}
                            variant={labelName.toLowerCase()}
                            title={<div className="icon-setting"></div>}
                            align="end"
                        >
                            {menuItem(item.child)}
                        </DropdownButton>
                    </div>
                );
            } else {
                return (
                    <div
                        className="cursor-point icon-setting"
                        key={`logoutMenu-${i++}`}
                        onClick={() => {
                            handleClickMenuItem(item);
                        }}
                    ></div>
                );
            }
        });
    },[])

    const goToRacingNote = (hasNotes) => {
        let peNoteLang = i18n.language === 'en' ? 'english' : 'chinese';
        let trCodeLang = i18n.language === 'en' ? 'E' : 'C';
        let page = hasNotes ? 'all' : 'new';
        let pop = NewWindow(
            window.globalConfig.PENOTES_URL +
            window.globalConfig.PENOTE_ENDPOINT.general
                .replace('{0}', peNoteLang)
                .replace('{1}', page)
                .replace('{2}', trCodeLang),
            'racingNotes',
            780,
            640,
            1,
            1
        );
        postMsgToPeNote(pop);
    };

    const RacingNoteIcon = useCallback(({globalContext, goToRacingNote}) => {
        let hasNotes = globalContext.racingNotesData?.noteData?.data?.hasGeneralNotes;
        return window.globalConfig.ENABLE_PE_NOTE && globalContext.racingNotesData.globalEnable && globalContext.racingNotesData.settingEnable ? (
            <div
                className={hasNotes ? 'icon-racingnoteUpdate' : 'icon-racingnote'}
                onClick={() => {
                    goToRacingNote(hasNotes);
                }}
            ></div>
        ) : null;
    },[]);

    const iconAreaWidth = document.querySelector('.menu_icon')?.offsetWidth || 42;

    const componentProps = {menuItem, loginKey, menuInfo, lang, handleClickMenuItem, isReadOnly, isSpeedbet}
    const racingNoteProps = {globalContext, goToRacingNote}

    return (
        <div className="betslip-menu">
            <div className="link-account" style={{ width: `calc(100% - ${iconAreaWidth}px)`,maxWidth: `calc(100% - ${iconAreaWidth}px)` }}>
                <LogoutMenu {...componentProps}/>
            </div>
            <div className="menu_icon">
                {loginKey&&globalContext.racingNotesData.settingEnable? (
                    <div className="betslip-note">
                        <RacingNoteIcon {...racingNoteProps}/>
                    </div>
                ) : null}
                <div className="betslip-setting">
                    <SettingMenu {...componentProps}/>
                </div>
            </div>
        </div>
    );
};

export const TandCText = ({ textData, isLoadTNCText }) => {
    return (
        <CustomScrollbars>
            {isLoadTNCText ?
                <Loading />
            :
                <div dangerouslySetInnerHTML={{ __html: textData }}></div>
            }
        </CustomScrollbars>
    );
};

export const TandCComp = ({ loginKey, closeCandTFn, isLoadTNCText, setIsLoadTNCText,setShowOverlay,showOverlay }) => {
    const { t } = useTranslation();
    const { msgLoading, getBetSlipLoginTnC } = useBetSlipMsgQuery();
    setShowOverlay(true);

    useEffect(() => {
        document.body.addEventListener('keydown',  onKeyEnter)
        return () => {
            document.body.removeEventListener('keydown',  onKeyEnter)
            setShowOverlay(false);
        }
    }, [])

    let textData = '';
    if (msgLoading) {
        setIsLoadTNCText(true);
    } else if (!msgLoading) {
        setIsLoadTNCText(false);
        textData = getBetSlipLoginTnC();
        if (textData === '') {
            closeCandTFn();
        }
    }

    const closeCandTClick = () => {
        if (isLoadTNCText) { return; }
        closeCandTFn();
        setShowOverlay(false);
    };

    const onKeyEnter = (e) =>{
        if((e.keyCode || e.which) == 13){
            if (isLoadTNCText) { return; }
            closeCandTFn();
            setShowOverlay(false);
        }
    }

    return (<>
        {showOverlay && <div className="overlay2" />}
        <div className="loginTandC">
            <div className="loginTandCHeader">{t('LB_BS_LOGIN_TANDC')}</div>
            <div className="loginTandCText">
                <TandCText textData={textData} isLoadTNCText={isLoadTNCText} />
            </div>
            <div className="loginTandCProceed">
                <div className={`tandCBtn${isLoadTNCText ? ` dim` : ''}`} onClick={closeCandTClick}>
                    {t('LB_BS_LOGIN_TANDCBTN')}
                </div>
            </div>
        </div>
        </>
    );

};

export const TimeOutWarnDialog = ({
    loginKey,
    closeTOdialogFn,
    calcTimeOutLogout,
    loginTimeoutRemind,
    resetLoginTimeout,
    sessionIdleTimeRef,
    warningIdleTimeRef,
    warningIdleTimeRef2
}) => {
    const [TCInfo, setTCInfo] = useState({});
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const [menuInfo, setMenuInfo] = useState({});
    const { betslipMenu } = useContext(ConfigContext);
    const {sessionServerTimeStamp} = useContext(GlobalContext);

    useEffect(() => {
        if (betslipMenu) {
            setMenuInfo(betslipMenu);
        }
    }, [betslipMenu]);

    const goOnLoginStateFn = () => {
        closeTOdialog();
        resetLoginTimeout();
    };

    const operateLogoutStateFn = () => {
        closeTOdialog();
        calcTimeOutLogout();
    };

    const TOText = () => {
        if (!menuInfo.timeOutRemind) {
            return <div></div>;
        }
        const { timeOutRemind } = menuInfo;
        let textData = lang === 'en' ? timeOutRemind.tOEn : timeOutRemind.tOCh;
        let remainingTime = new Date(sessionIdleTimeRef.current - sessionServerTimeStamp).getMinutes() + 1;
        // let remainingTime = 3;
        // const warningTimeout =
        //     !isNaN(+sessionStorage.getItem('WarningTimeout')) && sessionStorage.getItem('WarningTimeout')
        //         ? +sessionStorage.getItem('WarningTimeout')
        //         : betslipMenu.WarningTimeout;
        // if (loginTimeoutRemind == '1') {
        //     remainingTime = warningTimeout;
        // }
        // if (loginTimeoutRemind == '2') {
        //     remainingTime = 1;
        // }
        return (
            <>
                <div className="loginTORow">{textData.firText}</div>
                <div className="loginTORow">{textData.secText}</div>
                <div className="loginTORow otRedFont margB20">
                    {textData.thiText} {remainingTime} {textData.fouText}
                </div>
                <div className="loginTORow otBlueFont margB10" onClick={goOnLoginStateFn}>
                    {textData.fifText}
                </div>
                <div className="loginTORow otBlueFont margB10" onClick={operateLogoutStateFn}>
                    {textData.sixText}
                </div>
            </>
        );
    };

    const closeTOdialog = () => {
        closeTOdialogFn();
    };
    return (
        <div className="loginTOArea">
            <div className="loginTO">
                <div className="loginTOHeader">
                    <div className="switch-btn-icon open-btn-icon" onClick={() => closeTOdialog()}></div>
                </div>
                <div className="loginTOText">
                    <TOText />
                </div>
            </div>
        </div>
    );
};

export const ComingSoonLogoutDialog = ({
    showMsg = false,
    closeCSLogoutdialogFn,
    loginTimeoutRemind,
    logoutStateFn,
    setPassword,
    setLoginAccount,
    setIsReadOnly,
    sessionIdleTimeRef,
    warningIdleTimeRef,
    warningIdleTimeRef2
}) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const { betslipMenu } = useContext(ConfigContext);
    const globalContext = useContext(GlobalContext);
    const [menuInfo, setMenuInfo] = useState(betslipMenu);
    // useEffect(() => {
    //     if (showMsg) {
    //         setMenuInfo(betslipMenu);
    //     }
    // }, [showMsg]);

    useEffect(() => {
        if (loginTimeoutRemind === '3') {
            setLoginAccount(sessionStorage.getItem('sso_web_id'));
            setPassword('********');
            setIsReadOnly(true);
            glassboxCustomEvent('High_Security_Logout', getWebIDHashed());
            logoutStateFn();
            clearLoginSessionStorage();
            globalContext.esHook.clearEsStateAll();
        }
    }, [loginTimeoutRemind]);

    const onClickClose = () => {
        closeCSLogoutdialogFn();
    };

    const ComingSoonText = () => {
        // loginTimeoutRemind 超时情景
        //if (!menuInfo.timeOutSituation1 || !loginTimeoutRemind) { return <div></div> }
        const { timeOutSituation1, timeOutSituation2 } = menuInfo;

        const curSituation =
            loginTimeoutRemind === '1' || loginTimeoutRemind === '2' ? timeOutSituation1 : timeOutSituation2;
        let textData = lang === 'en' ? curSituation.tOEn : curSituation.tOCh;

        const date = new Date(globalContext?.sessionServerTimeStamp);
        let m = date.getMinutes();
        m = m >= 10 ? m : '0' + m;
        let h = date.getHours();
        h = h >= 10 ? h : '0' + h;

        let currentTime = `${h}:${m}`;

        const logOTime = new Date(globalContext?.sessionServerTimeStamp);
        // const warningTimeout =
        //     !isNaN(+sessionStorage.getItem('WarningTimeout')) && sessionStorage.getItem('WarningTimeout')
        //         ? +sessionStorage.getItem('WarningTimeout')
        //         : betslipMenu.WarningTimeout;
        // let remainingTime = 3;
        // if (loginTimeoutRemind == '1') {
        //     remainingTime = warningTimeout;
        // }
        // if (loginTimeoutRemind == '2') {
        //     remainingTime = 1;
        // }
        // let logOm = logOTime.getMinutes() + (remainingTime - 0);
        let logOm = new Date(sessionIdleTimeRef.current).getMinutes();
        logOm = logOm >= 10 ? logOm : '0' + logOm;
        let logOH = logOTime.getHours();
        logOH = logOH >= 10 ? logOH : '0' + logOH;
        if (logOm > 59) {
            logOm = logOm - 60;
            logOm = logOm >= 10 ? logOm : '0' + logOm;
            logOH = logOH - 0 + 1;
        }
        if (logOH > 23) {
            logOH = logOH - 24;
            logOH = logOH >= 10 ? logOH : '0' + logOH;
        }
        let logoutTime = `${logOH}:${logOm}`;

        return (
            <>
                <div className="ComeSoonLogoutRow">{textData.firText}</div>
                {loginTimeoutRemind === '1' || loginTimeoutRemind === '2' ? (
                    <>
                        <div className="ComeSoonLogoutRow">
                            {textData.secText} {currentTime}{' '}
                        </div>
                        <div className="ComeSoonLogoutRow">
                            {textData.thiText} {logoutTime} {textData.fifText}
                        </div>
                        <div className="ComeSoonLogoutRow">{textData.fouText}</div>
                    </>
                ) : (
                    <>
                        <div className="ComeSoonLogoutRow">
                            {textData.secText} {logoutTime} {textData.thiText}
                        </div>
                    </>
                )}
            </>
        );
    };

    return (
        <Popup open={showMsg} closeOnDocumentClick={false} className="logout-succ-msg comingSoonLogOutDialog">
            <div className="logout-succ-msg-container">
                <ComingSoonText />
                <div className="close-btn-box">
                    <div className="close" onClick={onClickClose}>
                        {t('LB_CLOSE')}
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export const FootballInplayMsgDialog = ({ setIsShowFTInplayDialog, FTInplayDialogDelay }) => {
    const [TCInfo, setTCInfo] = useState({});
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const [menuInfo, setMenuInfo] = useState({});
    const { betslipMenu } = useContext(ConfigContext);
    const ftInplayTimeoutRef = useRef(0);
    const ftInplayTimerRef = useRef(null);

    useEffect(() => {
        const outHeight = document.getElementById('divBetSlipNormal').offsetHeight;
        const loginHeight = document.getElementById('login').offsetHeight;
        const ftInplayDialog = document.getElementById('ftInplayDialog');
        ftInplayDialog.style.height = outHeight + 'px';

    }, []);

    useEffect(() => {
        if (betslipMenu) {
            const percentEle = document.getElementById('ftInplayPPercent');
            const loopTime = window.globalConfig.IS_IB
                ? (FTInplayDialogDelay * 1000) / 100
                : (betslipMenu.footballInplayTime * 1000) / 100;
            ftInplayTimeoutRef.current = 0;
            ftInplayTimerRef.current = setInterval(() => {
                ftInplayTimeoutRef.current += 1;
                percentEle.style.width = ftInplayTimeoutRef.current + '%';
                if (ftInplayTimeoutRef.current === 98) {
                    // setIsShowFTInplayDialog(false);
                    clearInterval(ftInplayTimerRef.current);
                    ftInplayTimerRef.current = null;
                }
            }, loopTime);
        } else {
            clearInterval(ftInplayTimerRef.current);
            ftInplayTimerRef.current = null;
        }
        return () => {
            clearInterval(ftInplayTimerRef.current);
            ftInplayTimerRef.current = null;
        };
    }, [betslipMenu]);

    return (
        <>
            <div className="dialogMask"></div>
            <div className="ftInplayDialog" id="ftInplayDialog">
                <div className="ftInplayContent">
                    <div className="ftInplayIcon"></div>
                    <div className="ftInplayFirText">{t('LB_BETSLIP_INPLAYMSGTEXT1')}</div>
                    <div className="ftInplaySecText">{t('LB_BETSLIP_INPLAYMSGTEXT2')}</div>
                    <div className="ftInplayProcess">
                        <div className="ftInplayPPercent" id="ftInplayPPercent"></div>
                    </div>
                </div>
            </div>
        </>
    );
};
