import { timeoutFetch } from '../Common/timeoutRequest';
import sha1 from 'js-sha1';
import Cookie from 'js-cookie'

const FetchApi = ({ timeout, options, params, url }) => {
    const onError = (e) => {
        console.error(e);
    };
    const onSuccess = (response) => {
        return response;
    };
    const timeoutErrData = {
        errCode: 'TIMEOUT'
    };
    return timeoutFetch({ timeout: timeout * 1000, onError, onSuccess, options, timeoutErrData })(url, params);
};

// re-call API after 2s if error returned
const retryFetch = async (errorFieldName, fetchFunc) => {
    const RETRY_COUNT = 1;
    // Returns a Promise that resolves after "ms" Milliseconds
    const timer = ms => new Promise(res => setTimeout(res, ms))

    const checkFetchSuccessFunc = (data) => {
        if (window.bsConfig.retry_error.filter((item) => data?.[errorFieldName]?.toUpperCase()?.indexOf(item) >= 0).length > 0) {
            return false;
        }
        return true;
    }

    let output = {};
    for (let i = 0; i <= RETRY_COUNT; i++) {
        output = await fetchFunc();
        console.debug('checkFetchSuccessFunc', checkFetchSuccessFunc(output), output)
        // if (checkFetchSuccessFunc(output) && i != 0) {
        // if (i == 0) output.stmt_error = 'system busy ehgiuhg'
        if (checkFetchSuccessFunc(output)) {
            return output;
        } else {
            await timer(2000);
        }
    }
    return output;
}

export const logoutIB = async (isNew = true) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/Logout.svc/DoLogoutTR`;
    if(!isNew){
        Cookie.remove('liteCookie')
    }
    var bodyValue = {
        acc: sessionStorage.getItem('account'),
        guid: sessionStorage.getItem('gu_id'),
        sid: sessionStorage.getItem('session_id'),
        isNew
    };

    return await fetch(url, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(bodyValue)
    })
        .then()
        .catch((err) => {
            console.error(err);
        });
};

export const logoutAsOnlyIB = async () => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/Logout.svc/DoLogoutASOnlyTR`;

    var bodyValue = {
        acc: sessionStorage.getItem('login_account'),
        guid: sessionStorage.getItem('gu_id'),
        sid: sessionStorage.getItem('session_id'),
        isNew: true
    };

    return await fetch(url, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(bodyValue)
    })
        .then()
        .catch((err) => {
            console.error(err);
        });
};

export const getSessionId_IB = async (callAPI) => {
    if (!callAPI) {
        return { sessionId: sessionStorage.getItem('session_id') };
    }

    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/Sid.svc/DoGetSID`;
    const bodyValue = {
        isNew: true
    }
    return await fetch(url, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(bodyValue)
    })
        .then((res) => {
            if (!res.ok && res.status == 401) {
                sessionStorage.setItem('access_token', '');
                sessionStorage.setItem('refresh_token', '');
            }
            return res.json();
        })
        .then((data) => {
            // transform back to cloud version structure
            const obj = {
                timestampId: 12345,
                sessionId: '' // to be obtained below
            };
            // store to session storage
            for (const item of data['DoGetSIDResult']) {
                sessionStorage.setItem(item['Key'], item['Value']);
                if (item['Key'] === 'session_id') {
                    obj['sessionId'] = item['Value'];
                }
            }
            // validate obj to be returned
            if (!obj.sessionId) {
                // throw new Error('Failed to get sid. data: ', data)
                console.error('Failed to get sid. data: ', data);
                return {};
            }
            // console.log(`getSessionID_IB completed. session_id: ${obj.sessionId}`);
            return obj;
        })
        .catch((err) => {
            console.error(err);
        });
};

export const checkAccountPasswordAPI_IB = async (lang, account, password) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/Login.svc/DoAuthentAccPwdHash`;
    const payload = {
        lang: lang == 'ch' ? 1 : 0,
        acc: account,
        pass: sha1(password).toUpperCase(),
        guid: sessionStorage.getItem('gu_id'),
        sid: sessionStorage.getItem('session_id'),
        isNew: true
    };
    return await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: JSON.stringify(payload)
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
            return { code: 415 };
        });
};

export const checkEKBAAnswerAPI_IB = async (ekbaAnswer, language, latitude, longitude, network) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/LoginEkba.svc/DoAuthentEKBA_V2TR`; //
    const payload = {
        lang: language == 'ch' ? 1 : 0,
        acc:
            sessionStorage.getItem('account') +
            (sessionStorage.getItem('bypassEodCode') != null ? sessionStorage.getItem('bypassEodCode') : ''),
        answer: sha1(ekbaAnswer.toUpperCase()).toUpperCase(),
        os: window.navigator.userAgent,
        ekbaLang: sessionStorage.getItem('ekbaLang'),
        ekbaId: sessionStorage.getItem('ekbaID'),
        webId: sessionStorage.getItem('webID'),
        hWebId: '',
        knownSSOGUID: '',
        guid: sessionStorage.getItem('gu_id'),
        sid: sessionStorage.getItem('session_id'),
        longitude: latitude,
        latitude: longitude,
        network: network,
        isNew: true
    };
    return await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: JSON.stringify(payload)
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
            return { code: 415 };
        });
};

export const getEkbaBySsoAuthen = async (language) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/Login.svc/DoAuthentAccSSOTR`;
    const payload = {
        lang: language == 'ch' ? 1 : 0,
        knownSSOGUID: sessionStorage.getItem('sso_guid'),
        guid: sessionStorage.getItem('gu_id'),
        sid: sessionStorage.getItem('session_id'),
        isNew: true
    };

    return await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: JSON.stringify(payload)
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
            return { code: 415 };
        });
};

export const getSetSequenceNumber = (isOpener = false) => {
    let seqNo = 0;
    if(window.BetLiteSite){
        seqNo = -(-localStorage.getItem('seq_no'));
        if (seqNo >= 900000) {
            seqNo = 9999;
        }
        seqNo = seqNo + 1;
        localStorage.setItem('seq_no', seqNo);
        return seqNo;
    }else{
        if (isOpener && window.opener?.sessionStorage.getItem('seq_no') != null) {
            seqNo = -(-window.opener.sessionStorage.getItem('seq_no'));
            if (seqNo >= 900000) {
                seqNo = 9999;
            }
            seqNo = seqNo + 1;
            window.opener.sessionStorage.setItem('seq_no', seqNo);
            sessionStorage.setItem('seq_no', seqNo);
        } else {
            seqNo = -(-sessionStorage.getItem('seq_no'));
            if (seqNo >= 900000) {
                seqNo = 9999;
            }
            seqNo = seqNo + 1;
            sessionStorage.setItem('seq_no', seqNo);
        }
        return seqNo;
    }
};

export const getBalance_IB_wrapped = async (isOpener = false, isLB = false, isNew = true) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/Balance.svc/DoBalanceTR`;
    const payload = {
        acc: sessionStorage.getItem('account'),
        seqNo: getSetSequenceNumber(isOpener),
        knownWebID: sessionStorage.getItem('sso_web_id') || '',
        knownSSOGUID: sessionStorage.getItem('sso_guid') || '',
        guid: sessionStorage.getItem('gu_id'),
        sid: sessionStorage.getItem('session_id'),
        isLB,
        isNew,
    };

    return await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: JSON.stringify(payload)
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            let dataObj = {};
            for (const item of data['DoBalanceTRResult']) {
                dataObj[item['Key']] = item['Value'];
            }
            const output = {
                accountNo: sessionStorage.getItem('account'),
                deviceType: 26,
                sessionId: sessionStorage.getItem('session_id'),
                timestampId: 8689,
                result: 0,
                resultMsg: '',
                balance: dataObj['ac_balance'] == '' ? '' : -(-dataObj['ac_balance']),
                balanceStatus: dataObj['balance_status'],
                balanceError: dataObj['balance_error'],
                sso_last_extend_error: dataObj['sso_last_extend_error']
            };
            return output;
        })
        .catch((err) => {
            console.error(err);
            return { code: 415 };
        });
}

export const getBalance_IB = async (isOpener = false, isLB = false, isNew = true) => {
    return await retryFetch('balanceError', () => getBalance_IB_wrapped(isOpener, isLB, isNew))

};

export const sendBetApi_IB = async ({ sessionData, bets, accessToken, language, seqNo, isRetry, isLB = false, isNew = true }) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/SendBet.svc/DoSendBetTR`;
    let betLineIds  = [];
    let betlinesList =
        bets.map((el) => {
                betLineIds.push(el.betLineGuid);
                return el.betline;
            });

    let betlines = betlinesList.join('\\') + '\\';
    let betlinesAT =
        bets.map(item => {
            return item.atDetail ? item.atDetail : item.betline
        })
            .join('\\') + '\\';
    betLineIds = betLineIds.join('|');
    if (window.myflag) betlines = window.myflag; // demo or testing in console
    const payload = {
        lang: language == 'ch' ? 1 : 0,
        acc:
            sessionStorage.getItem('account') +
            (sessionStorage.getItem('bypassEodCode') != null ? sessionStorage.getItem('bypassEodCode') : ''),
        seqNo: seqNo ? seqNo : getSetSequenceNumber(),
        betlines: betlines,
        betlinesAT: betlinesAT, // TODO
        betLineIds,
        knownWebID: sessionStorage.getItem('sso_web_id'),
        knownSSOGUID: sessionStorage.getItem('sso_guid'),
        guid: sessionStorage.getItem('gu_id'),
        sid: sessionStorage.getItem('session_id'),
        delayReq: seqNo ? '1' : '0',
        isNew,
        isRetry: isRetry,
        isV2: true
    };
    if(isLB){
        payload.isLB = true;
    }

    const timeout = sessionStorage.getItem('BetReplyTimeout');
    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    };
    return await FetchApi({ options, timeout, params: payload, url })
        .then((data) => {
            if (data.errCode) {
                return data;
            }
            let dataObj = {};
            let betarr = [];
            for (const item of data['DoSendBetTRResult']) {
                dataObj[item['Key']] = item['Value'];
            }
            //check EOD
            if (dataObj.login_status == 'EOD') {
                window.sessionStorage.setItem('_OOSMsgId', dataObj.OosMsgId);
                window.sessionStorage.setItem('_OOSEngContent', dataObj.OosMsgEn);
                window.sessionStorage.setItem('_OOSChiContent', dataObj.OosMsgCh);
            }

            // inplay
            if (dataObj.delay_time_in_seconds && dataObj.is_delay_reply === '1') {
                return {
                    delay_time_in_seconds: dataObj.delay_time_in_seconds,
                    is_delay_reply: dataObj.is_delay_reply,
                    delay_request_seq_no: dataObj.delay_request_seq_no
                };
            }
            // retry
            if (dataObj.sendbet_error && dataObj.sendbet_error.indexOf('159') >= 0) {
                return dataObj;
            }
            //SSO
            if (dataObj.sendbet_status == '-2') {
                return dataObj;
            }

            let sendBetReplyArr = dataObj.sendbet_reply.split('@@@');
            const errMsgEnArr = dataObj.errMsgEn.split('@@@');
            const errMsgChArr = dataObj.errMsgCh.split('@@@');
            sendBetReplyArr.shift();
            //console.log('sendBetReplyArr', sendBetReplyArr);

            // for each bet
            for (let i = 0; i < sendBetReplyArr.length; i++) {
                let result = -1;
                let totalInvAmt = 0;
                let txNo;
                let resultMsg;
                let resultMsgChi;
                let flexiUnitBet = "";
                let betReplyData = sendBetReplyArr[i].split('|||');
                let betInfoRow = null;
                let newInvestment = {};
                if (betReplyData[0] === 'ACCEPTED') {
                    result = 0;
                    txNo = betReplyData[1];
                    totalInvAmt = betReplyData[2];
                    if (betReplyData[4]) {
                        flexiUnitBet =  betReplyData[4];
                    }
                    // auto acceptance
                    //betReplyData[8] = "xxx@999";
                    if (betReplyData[8]) {
                        let newOdds = getNewOddsList(betReplyData[8]);
                        newInvestment.newOdds = newOdds;
                        betInfoRow = {
                            oddsFlag: 0,
                            newInvestment: newInvestment
                        };
                    }
                } else if (betReplyData[0] === 'REJECTED') {
                    result = 1;
                    resultMsg = errMsgEnArr[i + 1];
                    resultMsgChi = errMsgChArr[i + 1];
                } else if (betReplyData[0] === 'UNKNOWN_SPECIAL') {
                    result = 1;
                    resultMsg = errMsgEnArr[i + 1];
                    resultMsgChi = errMsgChArr[i + 1];
                } else if (betReplyData[0] === 'COUNTER') {
                    result = 0;
                    resultMsg = betReplyData[1];
                    resultMsgChi = betReplyData[1];
                    let counterOfferType = null;
                    if (betReplyData[7] && betReplyData[8]) {
                        // both amount & odds change
                        counterOfferType = 3;
                        newInvestment.proposedAmount = betReplyData[7];
                        let newOdds = getNewOddsList(betReplyData[8]);
                        newInvestment.newOdds = newOdds;
                    } else if (betReplyData[7]) {
                        // amount change
                        counterOfferType = 2;
                        newInvestment.proposedAmount = betReplyData[7];
                    } else if (betReplyData[8]) {
                        // odds change
                        counterOfferType = 1;
                        let newOdds = getNewOddsList(betReplyData[8]);
                        newInvestment.newOdds = newOdds;
                    }
                    betInfoRow = {
                        counterOfferKey: betReplyData[6],
                        systemCounterOfferCount: betReplyData[5],
                        counterOfferType: counterOfferType,
                        oddsFlag: 3,
                        newInvestment: newInvestment
                    };
                }
                //console.log('betReplyData', betReplyData);

                let betResultArr = {
                    translatorBetline: bets[i].betline,
                    atDetail: null,
                    noOfComb: 1,
                    result: result,
                    resultMsg: resultMsg,
                    resultMsgChi: resultMsgChi,
                    totalInvAmt: totalInvAmt,
                    txNo: txNo,
                    betInfo: betInfoRow,
                    flexiUnitBet: flexiUnitBet
                };
                betarr.push(betResultArr);
            }

            const newBalance =
                dataObj.new_balance && dataObj.bet_total ? dataObj.new_balance - dataObj.bet_total : null;

            sessionStorage.setItem("balance",newBalance);

            let dataResult = {
                betTime: dataObj.trans_time,
                betResult: betarr,
                newBalance: newBalance
            };
            return dataResult;
        })
        .catch((err) => {
            console.error(err);
            return { code: 415 };
        });
};

function formatDate(date) {
    return date.slice(6, 8) + '/' + date.slice(4, 6) + '/' + date.slice(0, 4);
    // const datestring = date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8);
    // const today = new Date(datestring);
    // const yyyy = today.getFullYear();
    // let mm = today.getMonth() + 1; // Months start at 0!
    // let dd = today.getDate();

    // if (dd < 10) dd = '0' + dd;
    // if (mm < 10) mm = '0' + mm;

    // return dd + '/' + mm + '/' + yyyy;
}

const getNewOddsList = (data) => {
    let newOdds = data.split('+');
    newOdds = newOdds.map((item) => {
        const [ids, oddsVal] = item.split('@');
        const [poolId, lineId, combId] = ids.split(',');
        return {
            poolId,
            lineId,
            oddsVal,
            combId
        };
    });
    return newOdds;
};

function mapBetType(betType) {
    switch (betType) {
        case '4':
            return '3';
    }
    return betType;
}


export const getStatment_IB = async (params, lang) => {
    return await retryFetch('stmt_error', () => getStatment_IB_wrapped(params, lang));
}

const getStatment_IB_wrapped = async (params, lang) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/Statement.svc/DoStatementSearchTR`;
    const reqCount = sessionStorage.getItem('OnlineStmtRecPerPage');
    const payload = {
        lang: lang == 'ch' ? 1 : 0,
        acc: sessionStorage.getItem('account'),
        knownWebID: sessionStorage.getItem('sso_web_id'),
        knownSSOGUID: sessionStorage.getItem('sso_guid'),
        guid: sessionStorage.getItem('gu_id'),
        sid: sessionStorage.getItem('session_id'),
        reqCount: reqCount,
        curDate: params.searchType == 'next' ? params.startDate : formatDate(params.startDate),
        endDate: params.searchType == 'next' ? params.endDate : formatDate(params.endDate),
        lastStatReq: params.lastStatReq ? params.lastStatReq : '', //sessionStorage.getItem('stmt_lastStatRequest') ? sessionStorage.getItem('stmt_lastStatRequest') : '',
        incompleteSeg: params.incompleteSeg ? params.incompleteSeg : '@@@@@@@@@', //sessionStorage.getItem('stmt_inSeg') ? sessionStorage.getItem('stmt_inSeg') : '@@@@@@@@@',
        txnType: params.displayType, //sessionStorage.getItem('stmt_txnType'),
        betType: params.transType, //sessionStorage.getItem('stmt_betType'),
        seqNo: getSetSequenceNumber(true),
        openBal: params.openingBalance,
        isNew: params.isNew ?? true,
        isLB: params.isLB
    };
    const timeout = 30;
    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    };
    return await FetchApi({ options, timeout, params: payload, url })
        .then((data) => {
            if (data.errCode) {
                return data;
            }
            let dataObj = {};
            for (const item of data['DoStatementSearchTRResult']) {
                sessionStorage.setItem(item['Key'], item['Value']);
                dataObj[item['Key']] = item['Value'];
                if (item['Key'] == 'seq_no') {
                    window.opener?.sessionStorage?.setItem('seq_no', item['Value']);

                    if(window.BetLiteSite){
                        localStorage.setItem('seq_no', item['Value']);
                    }
                }
            }
            if (dataObj.stmt_error) {
                return { ...data, stmt_status: dataObj.stmt_status };
            }
            //@@@0103;;;CD;;;0;;;;;;;;;$;;;$90000254.50;;;;;;;;;戶口結存 12-FEB-2022;;;
            //@@@0105;;;04;;;0;;;12-02-2022;;;18:06;;;$10.00;;;$;;;六合彩;;;;;;22/071&nbsp;期&nbsp; 17 + 25 + 29 + 32 + 38 + 49&nbsp;<br>$10<br>&nbsp;(運財號碼)<br>;;;
            // 0: "txnNo"
            // 1: "txnType"
            // 2: "txnFlag"
            // 3: "txnDate"
            // 4: "txnTime"
            // 5: "debit"
            // 6: "credit"
            // 7: "betType"
            // 8: "venueDay"
            // 9: "txnDetail"
            // 10: "isEwallet"
            const transactionContent = [];
            const transactionArr = dataObj.stmt_dtls?.split('@@@');
            transactionArr.shift();
            // if (transactionArr.length > reqCount) { // next page first item
            //     transactionArr.pop();
            // }
            for (const line of transactionArr) {
                const items = line.split(';;;');
                let transaction = {
                    betType: {
                        eng: items[7],
                        chi: items[7],
                        raw: items[7]
                    },
                    transactionNumber: {
                        eng: items[0],
                        chi: items[0]
                    },
                    venueDay: {
                        eng: items[8],
                        chi: items[8]
                    },
                    detail: {
                        eng: items[9],
                        chi: items[9],
                        raw: items[9]
                    },
                    creditAmount: {
                        eng: items[6] != '$' ? items[6] : '',
                        chi: items[6] != '$' ? items[6] : ''
                    },
                    debitAmount: {
                        eng: items[5] != '$' ? items[5] : '',
                        chi: items[5] != '$' ? items[5] : ''
                    },
                    transactionDateTime: items[3] + ' ' + items[4],
                    payout: '', //items[5],
                    payoutType: '',
                    eWallet: items[10],
                    txnType: items[1],
                    txnFlag: items[2],
                    rowStr: line
                };
                transactionContent.push(transaction);
            }
            return {
                accountNo: sessionStorage.getItem('account'),
                channelId: 13,
                sessionId: sessionStorage.getItem('session_id'),
                timestampId: 94370,
                token: '',
                result: 0,
                resultMsg: '',
                continue: dataObj.isLastPage != 'True',
                continuationDate: 0,
                continuationKey: 12345,
                transactionContent: transactionContent,
                LastStatReq: dataObj.stmt_lastStatRequest,
                IncompleteSeg: dataObj.stmt_inSeg,
                curDate: dataObj.stmt_curDate,
                endDate: dataObj.stmt_endDate,
                openBal: dataObj.stmt_openBal
            };
        })
        .catch((err) => {
            console.error(err);
            return { code: 415 };
        });
};

export const getTicketImg_IB = async (params) => {
    const url = `${window.globalConfig.STATEMENT_URL}/services/Ewallet.svc/DoGetReceipt`;
    const payload = {
        data: params,
        isNew: true
    };
    const timeout = 30;
    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    };
    return await FetchApi({ options, timeout, params: payload, url })
        .then((data) => {
            if (data.errCode) {
                return data;
            }
            let dataObj = {};
            for (const item of data['DoGetReceiptResult']) {
                dataObj[item['Key']] = item['Value'];
            }
            return dataObj;
        })
        .catch((err) => {
            console.error(err);
            return { code: 415 };
        });
};

export const getStatementRecall_IB = async (params, lang, t, isLB = false, isNew = true) => {
    return await retryFetch('recall_error', () => getStatementRecall_IB_wrapped(params, lang, t, isLB, isNew));
}

const getStatementRecall_IB_wrapped = async (params, lang, t, isLB = false, isNew = true) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/Recall.svc/DoRecallTR`;
    const payload = {
        lang: lang == 'ch' ? 1 : 0,
        acc: sessionStorage.getItem('account'),
        webId: sessionStorage.getItem('webID') || '',
        knownWebID: sessionStorage.getItem('sso_web_id') || '',
        knownSSOGUID: sessionStorage.getItem('sso_guid') || '',
        guid: sessionStorage.getItem('gu_id'),
        sid: sessionStorage.getItem('session_id'),
        isLB,
        lastTxnNo: params.lastTxnNo ? params.lastTxnNo : '0000',
        seqNo: getSetSequenceNumber(true),
        isNew,
    };
    return await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: JSON.stringify(payload)
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            let dataObj = {};
            for (const item of data['DoRecallTRResult']) {
                dataObj[item['Key']] = item['Value'];
                sessionStorage.setItem(item['Key'], item['Value']);
            }
            // 0105###六合彩###22/071&nbsp;期&nbsp;&nbsp;17&nbsp;+&nbsp;25&nbsp;+&nbsp;29&nbsp;+&nbsp;32&nbsp;+&nbsp;38&nbsp;+&nbsp;49&nbsp;<br>$10<br>&nbsp;(運財號碼)######10.00
            // 0: "0109"
            // 1: "六合彩"
            // 2: "22/071&nbsp;期&nbsp;&nbsp;21&nbsp;+&nbsp;24&nbsp;+&nbsp;37&nbsp;+&nbsp;41&nbsp;+&nbsp;42&nbsp;+&nbsp;49&nbsp;<br>$10<br>&nbsp;(運財號碼)"
            // 3: ""
            // 4: "10.00"
            const transactionContent = [];
            const transactionArr = dataObj.recall_dtls?.split(';;;');
            for (const line of transactionArr) {
                const items = line.split('###');

                let itemsDetail = items[2];
                let itemsTotalAmount = items[4];
                if (itemsTotalAmount.indexOf('CANCELLED') >= 0) {
                    if (itemsDetail.lastIndexOf("$") >= 0) {
                        itemsDetail = itemsDetail.substr(0, itemsDetail.lastIndexOf("$")) + t('ACCOUNT_STATEMENT_TRAN_CANCEL');
                    }
                    itemsTotalAmount = t('ACCOUNT_STATEMENT_TRAN_CANCEL_S');
                }else{
                    itemsTotalAmount = '$' + itemsTotalAmount;
                }
                let transaction = {
                    betType: {
                        eng: items[1],
                        chi: items[1],
                        raw: items[1]
                    },
                    transactionNumber: {
                        eng: items[0],
                        chi: items[0]
                    },
                    detail: {
                        eng: itemsDetail,
                        chi: itemsDetail,
                        raw: itemsDetail
                    },
                    totalAmount: itemsTotalAmount,
                    payoutType: '', //"HR",
                    unit: '' //"$10"
                };
                transactionContent.push(transaction);
            }
            return {
                accountNo: sessionStorage.getItem('account'),
                betTime: '15-06-2022 17:46', //?
                continuationId: 986, //?
                continue: dataObj.lastTxnNo && dataObj.lastTxnNo != '0000',
                deviceType: 26,
                result: 0,
                resultMsg: '',
                sessionId: sessionStorage.getItem('session_id'),
                timestampId: 28640,
                token: null,
                transactionContent: transactionContent,
                lastTxnNo: dataObj.recall_lastTxnNo,
                recall_status: dataObj.recall_status
            };
        })
        .catch((err) => {
            console.error(err);
            return { code: 415 };
        });
};

export const ticketExtendSession_IB = async () => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/SSOService.svc/DoTicketExtendTR`;
    const payload = {
        knownWebID: sessionStorage.getItem('sso_web_id'),
        knownSSOGUID: sessionStorage.getItem('sso_guid'),
        isNew: true
    };
    return await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: JSON.stringify(payload)
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
            return { code: 415 };
        });
};

export const extendSession_IB = async (isNew = true, isLB = false) => {
    const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/Session.svc/ExtendSessionTR`;
    const payload = {
        acc: sessionStorage.getItem('account'),
        seqNo: getSetSequenceNumber(),
        sid: sessionStorage.getItem('session_id'),
        isNew,
        isLB
    };
    return await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: JSON.stringify(payload)
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
            return { code: 415 };
        });
};
