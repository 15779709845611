import React, { useState, useContext, useCallback } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate  } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { EmptyTable } from '../../Common/EwinHomeCommon';
import { capitalize, isLocalMeeting, NewWindow } from '../../Common/home-common';
import { formatDateTime, scrollTo } from '../../Common/GlobalFunc';
import MobileTabSet from '../../Common/MobileTabSet';
import { LandingContext } from '../../Home/LangingPage';
import { GetRaceConfig  } from '../../Common/ConfigHelper';
import { formatRacingDate, translateChangesItem, PenetroReading, HammerReading, showWorldPoolLogoLanding, hasJpt, hasEst, filterChangeItems, sortChanges, getDefaultMeeting } from './Common';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { redirectJCRW } from './Common';

const EwinHomeRacingBlock = () => {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const context = useContext(LandingContext);
    const { data, loading } = context.racingData;

    const CustomScrollbars = props => (
        <Scrollbars
            autoHeight
            autoHeightMin={140}
            autoHeightMax={140}
            // autoHide
            // autoHideTimeout={1000}
            // autoHideDuration={200}
            {...props}
        />
    );

    const CustomScrollbarsChanges = props => (
        <Scrollbars
            autoHeight
            autoHeightMin={data?.raceMeetings[0]?.changeHistories?.length > 2 ? 90 : 140}
            autoHeightMax={data?.raceMeetings[0]?.changeHistories?.length > 2 ? 90 : 140}
            {...props}
        />
    );

    const [tabs, setTabs] = useState([
        { type: "G", name1: 'LB_RACE_INFO_BLOCK_GEN', disabled: false, checked: true },
        { type: "P", name1: 'LB_RACE_INFO_BLOCK_POOL_INFO', disabled: false, checked: false },
        { type: "C", name1: 'LB_RACE_INFO_BLOCK_CHANGE', disabled: false, checked: false }
    ]);

    const currentType = () =>{
        return tabs.filter(d => d.checked)[0].type;
    }

    const changeTab = (value) => {
        // let value = event.currentTarget.getAttribute("value");
        let oldType = currentType();
        if (oldType == value) {
            return;
        }
        for (let idx in tabs) {
            if (tabs.hasOwnProperty(idx)) {
                tabs[idx].checked = false;
                if (tabs[idx].type === value) {
                    tabs[idx].checked = true;
                }
            }
        }
        setTabs([...tabs]);
    }

    const JackPotInfo = ({ mtg, betType }) => {
        let entry = [];
        for (let i = 1; i <= mtg.totalNumberOfRace; i++) {
            let pool = mtg.jpEsts.filter(item => item.leg.races[0] == i && item.oddsType == betType && item.jackpot != null && item.jackpot != "")[0];
            if (pool != null) {
                entry.push(<>
                    <div className='font-weight500'>{t('LB_RC_ODDS_TABLE_RACENO')}: {i}</div>
                    <div className="font-color-red">${parseInt(pool.jackpot).toLocaleString()}</div>
                </>);
            }
        }
        if (entry.length > 0) {
            return <>
                <div className="blockPoolInfoBody top-border">
                    <div className='font-weight500'>{t(betType == 'FF' ? 'LB_RC_QTTFF_MERGE' : 'LB_RC_TCETRI_MERGE')}</div>
                    <div >{t('LB_RC_JACKPOT')}</div>
                </div>
                <div className="blockPoolInfoBody top-border">
                    {entry}
                </div>
            </>
        }
        return null;
    }

    const EstDivInfoByType = ({ mtg, betType }) => {
        let entry = [];
        let lbl = betType==='SixUP' ? t('LB_6UP_WIN_BONUS') : t('LB_' + betType);
        let hasMultiPools = betType==="DT" || mtg.jpEsts.filter(item => item.oddsType == betType).length > 1;
        for (let i = 1; i <= mtg.totalNumberOfRace; i++) {
            let pool = mtg.jpEsts.filter(item => item.leg.races[0] == i && item.oddsType == betType && item.estimatedDividend != null && item.estimatedDividend != "")[0];
            if (pool != null) {
                entry.push(<>
                    <div>{hasMultiPools && pool.leg.number >0 ? t('LB_RC_POOL_NO_' + pool.leg.number) : ''}{lbl}</div>
                    <div className="font-color-red font-weight500">${parseInt(pool.estimatedDividend).toLocaleString()}</div>
                </>);
            }
        }
        return entry;
    }

    const EstDivInfo = ({mtg}) => {
        let entry = [];
        entry = entry.concat(<EstDivInfoByType mtg={mtg} betType="TT" />);
        entry = entry.concat(<EstDivInfoByType mtg={mtg} betType="SixUP" />);
        entry = entry.concat(<EstDivInfoByType mtg={mtg} betType="DT" />);

        if (hasEst(mtg)) {
            return <>
                <div className="blockPoolInfoBody top-border">
                    <div className='font-weight500'>{t('LB_RACE_INFO_BLOCK_POOL')}</div>
                    <div >{t('LB_RACE_INFO_BLOCK_ED')}</div>
                </div>
                <div className="blockPoolInfoBody top-border">
                    {entry}
                </div>
            </>
        }
        return null;
    }

    const GeneralInfo = useCallback(({data}) => {
        const mtg = getDefaultMeeting(data.raceMeetings, data.timeOffset?.rc, GetRaceConfig());
        if ( mtg==null ) {
            return <div className="blockBody">{t('LB_RC_JTC_NOINFO')}</div>
        }
        let firstRaceNo = Math.min(...mtg.races.map(x=>x.no));
        let firstRace = mtg.races.filter(x => x.no == firstRaceNo)[0];
        let curRaceNo = mtg.currentNumberOfRace == 0 ? firstRaceNo : mtg.currentNumberOfRace;
        let raceObj = mtg.races.filter(x => x.no == curRaceNo)[0];
        let desc = [];
        if (raceObj && raceObj.raceTrack['description_' + i18n.language] != null && raceObj.raceTrack['description_' + i18n.language] != '')
            desc.push(raceObj.raceTrack['description_' + i18n.language]);
        if (raceObj && raceObj.raceCourse['displayCode'] != null && raceObj.raceCourse['displayCode'] != '')
            desc.push(t('LB_RACE_RACE_COURSE').replace('{0}', raceObj.raceCourse['displayCode']));
        if (raceObj && raceObj['go_' + i18n.language] != null && raceObj['go_' + i18n.language] != '')
            desc.push(raceObj['go_' + i18n.language]);

        return <div className="blockBody">
            <CustomScrollbars>
                <div className="blockGenaralInfoBody">
                    <div className='font-weight500'>{t('LB_RACE_INFO_BLOCK_MEETING')}</div>
                    <div style={{wordBreak: 'keep-all'}}>{formatRacingDate(mtg.date)}<wbr />({t('LB_' + mtg.dateOfWeek + '_SHORT').toUpperCase()})</div>
                    <div className='font-weight500'>{`${t('LB_' + mtg.venueCode)}${t('LB_RACE_INFO_' + mtg.meetingType)}`}:</div>
                    <div>{mtg.totalNumberOfRace} {t('LB_RACE_INFO_RACES')}</div>
                    <div className='font-weight500'>{t('LB_RACE_INFO_BLOCK_START')}</div>
                    <div>{formatDateTime(firstRace?.postTime)?.[1]}</div>
                    <div className="span2 font-weight500">{desc.join(', ')}</div>
                    <PenetroReading reading={mtg.penetrometerReadings[0]} t={t} path="Home" />
                    <HammerReading reading={mtg.hammerReadings[0]} t={t} path="Home" />
                    {showWorldPoolLogoLanding(mtg) ? <div className="meeting-info-wplogo" onClick={()=> { NewWindow(window.globalConfig[`WP_URL_GL_${i18n.language}`])}}></div> : null}
                </div>
            </CustomScrollbars>
        </div>
    },[]);

    const PoolInfo = useCallback(({data}) => {
        const mtg = getDefaultMeeting(data.raceMeetings, data.timeOffset?.rc, GetRaceConfig());
        if ( mtg==null ) {
            return <div className="blockBody">{t('LB_RC_JTC_NOINFO')}</div>
        }

        return <div className="blockBody">
            <CustomScrollbars>
                <div className="blockPoolInfoTitle">
                    <div className='font-weight500'>{t('LB_RACE_INFO_BLOCK_MEETING')}</div>
                    <div>{formatRacingDate(mtg.date)} ({t('LB_' + mtg.dateOfWeek + '_SHORT').toUpperCase()})</div>
                </div>
                <JackPotInfo mtg={mtg} betType="TRI" />
                <JackPotInfo mtg={mtg} betType="FF" />
                <EstDivInfo mtg={mtg} />
                {!hasJpt(mtg) && !hasEst(mtg) ? <div className="blockPoolInfoBody"><div className="span2 font-color-red" style={{ textAlign: "center"}}>{isLocalMeeting(mtg.venueCode) ? t('LB_RACE_INFO_SHORTLY') : t('LB_RC_JTC_NOINFO')}</div></div> : null}
            </CustomScrollbars>
        </div>
    },[]);

    const Changes = useCallback(({mtg}) => {
        const filterChanges = mtg.changeHistories.filter(x=>filterChangeItems(x));
        if ( filterChanges.length==0 ) {
            return <div>{t('LB_RACE_CHANGES_NOCHG')}</div>
        }
        filterChanges.sort(sortChanges);
        return filterChanges.map((item) => {
            let msg = `${t('LB_' + mtg.venueCode)}${i18n.language=="en" ? " " : ""}${translateChangesItem(t, item, i18n.language, true)} (${moment(item.time).format("DD/MM HH:mm")})`;
            return <div onClick={()=> { NewWindow(`${window.globalConfig.JCRW_URL}${t('URL_RC_JCRW_CHANGES')}`, '', 770, 500, 1, 1); }} className="blockChangesRow font-weight500">&gt; {msg}</div>
        });
    },[]);

    const ChangesTable = useCallback(({data}) => { 
        const mtg = getDefaultMeeting(data.raceMeetings, data.timeOffset?.rc, GetRaceConfig());
        if ( mtg==null ) {
            return <div className="blockBody">{t('LB_RC_JTC_NOINFO')}</div>
        }

        return <div className="blockBody">
            <CustomScrollbarsChanges>
                <div className="blockChanges">
                    <Changes mtg={mtg} />
                </div>
            </CustomScrollbarsChanges>
            {mtg.changeHistories.filter(x=>filterChangeItems(x)).length >= 3 ?
                <div className="blockButton">
                    <div className="blockChangesMore btn" onClick={() => { redirectJCRW(window.globalConfig.JCRW_URL + t('URL_RC_JCRW_CHANGES')); }}>{t('LB_RACE_CHANGES_MORE')}</div>
                </div> : null }
        </div>
    },[]);

    const tabsProps = {data }

    return (
        <section className="HRBlock">
            <div role='button' tabIndex='0' className="blockHeader" onClick={()=>{ scrollTo(0, 0); navigate(`/${i18n.language}/racing`) }}>
                <div>{t('LB_BMENU_RACING')}</div>
                <ArrowIcon className='hueRotate' direction='right' size={24}/>
            </div>

            <MobileTabSet className="hrTab" items={tabs} handleRadioClick={changeTab} />

            {loading && <EmptyTable/> }
            {!loading && currentType()==="G" && <GeneralInfo {...tabsProps} />}
            {!loading && currentType()==="P" && <PoolInfo {...tabsProps} />}
            {!loading && currentType()==="C" && <ChangesTable {...tabsProps} />}

        </section>
    );
}
export default EwinHomeRacingBlock;
