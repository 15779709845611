import React, { useCallback, useEffect, useMemo, useState, useRef, useContext } from 'react';
import { GlobalContext } from '../../contexts/GlobalContext';


export function isMobileSize() {
    return ( window.matchMedia("(max-width: 1023px)").matches && window.matchMedia("(pointer:coarse)").matches ) && sessionStorage.getItem('isDeskTopVersion') !=='1'
}

export function getScreenInfo() {
    return {
        width: window.innerWidth,
        height: window.innerHeight,
        isMobileSize: isMobileSize()
    };
}

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
        isMobileSize: isMobileSize()
    });
    const timer = useRef(null);
    useEffect(() => {
        function handleResize() {
            timer.current !== null && clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                setWindowSize({ ...getScreenInfo() });
            }, 250);
        }
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Call handler right away so state gets updated with initial window size
        setWindowSize({ ...getScreenInfo() });
        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
            timer.current !== null && clearTimeout(timer.current);
        }
    }, []);

    return windowSize;
};

const defalutBreakpoints = { Desktop: 1920, size1440: 1440, size1279: 1279, mobile: 768 };
export const useBreakpoint = (breakpoints = defalutBreakpoints) => {
    const sortedBreakpoints = useMemo(() => {
        return Object.entries(breakpoints).sort((a, b) => (a[1] >= b[1] ? -1 : 1));
    }, [breakpoints]);
    const calcBreakpoint = useCallback(() => {
        const point = sortedBreakpoints.reduce((acc, [name, width]) => {
            if (width >= window.innerWidth) {
                return name;
            } else {
                return acc;
            }
        }, sortedBreakpoints[0][0]);
        // console.log(point)
        return point;
    }, [sortedBreakpoints]);
    const [point, setPoint] = useState(() => {
        return calcBreakpoint();
    });
    useEffect(() => {
        const handlePoint = () => {
            setPoint(calcBreakpoint());
        };
        window.addEventListener('resize', handlePoint);
        return () => {
            window.removeEventListener('resize', handlePoint);
        };
    }, [calcBreakpoint]);
    return point;
};


export const useUpdateRNotes = () => {
    const [updateRNotes, setUpdateRNotes] = useState("");
    const timer = useRef(null);
    useEffect(() => {
        timer.current = setInterval(() => {
            if ( updateRNotes != window.updateRNotes ) {
                setUpdateRNotes(window.updateRNotes);
            }
        }, 1000);

        // Remove event listener on cleanup
        return () => {
            timer.current !== null && clearInterval(timer.current);
        }
    }, []);

    return updateRNotes;
};

export const useShowAlertDialog = () =>{
    const { isMobileSize } = useWindowSize();
    const {setMobileAlertObj} = useContext(GlobalContext);

    const getAlertShowMode = (message) => {
        if(isMobileSize){
            setMobileAlertObj({
                isShow: true,
                alertMsg: message,
            })
        }else{
            alert(message);
        }
    }

    return {
        getAlertShowMode,
    }
}

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

export const useDynamicStyles = (classNames) => {
    useEffect(() => {
        if (Array.isArray(classNames)) {
            classNames.forEach((className) => {
                document.querySelector('body').classList.add(className);
            });
        }

        return () => {
            if (Array.isArray(classNames)) {
                classNames.forEach((className) => {
                    document.querySelector('body').classList.remove(className);
                });
            }
        };
    }, []);
};