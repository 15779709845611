import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import OddsTable from '../Common/RCOddsTable';
import OddsDropLegend from '../Common/OddsDropLegend';
import PoolInvTable from '../Common/PoolInvTable';
import InvestmentCalculator from '../Common/InvestmentCalculator';
import { getComingleMsgs, isStartSell } from '../Common/Common';
import { RacingContext } from '../../Home/RacingPage';
import AddSlipBtn from '../Common/AddSlipBtn';
import {MobileCurrentOdds} from '../Common/CurrentOdds';
import Loading from '../../Common/ui-components/Loading';

const OddsWP = (props) => {
    const context = useContext(RacingContext);

    const RCColumns = {
        alw: true,
        no: true,
        colour: true,
        horseName: true,
        rcNote: context.content.isPeNoteEnable,
        draw: true,
        wt: true,
        jocky: true,
        trainer: true,
        bodyWT: false,
        rtg: false,
        gear: false,
        last6: false,
        pwin0: false,
        pwin1: false,
        win: true,
        place: true,
        wp: true
    };



    if (context == null) {
        return  <Loading/>
    } else {

        return (
            <Content context={context} RCColumns={RCColumns}/>
        );
    }
}
export default OddsWP;

const Content = ({context, RCColumns}) => {

    const { t, i18n } = useTranslation();

    if (context != null) {
        const mtg = context.content.meeting;

        let tmpMsgs = getComingleMsgs(mtg, context.content.page, context.content.raceNo);
        let pageConfig = context.pageConfig;
        let comingleMsg1 = [];
        let comingleMsg2 = [];
        if (tmpMsgs != null) {
            let comingleLang = i18n.language == "en" ? "E" : "C";
            comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}1: </span>{tmpMsgs['NOTE1_' + comingleLang]}</div>);
            comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}2: </span>{tmpMsgs['NOTE2_' + comingleLang]}</div>);
            comingleMsg2.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}1: </span>{tmpMsgs['NOTE3_' + comingleLang]}</div>);
            comingleMsg2.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}2: </span>{tmpMsgs['NOTE4_' + comingleLang]}</div>);
        }
        let betSlip
        if (pageConfig.showAddToSlip) {

            betSlip = (
                <div style={{marginTop: 6}} className={pageConfig.hiddenAddToSlip? "AddSlipBtn-Content hiddenAddSlip" : "AddSlipBtn-Content"}>
                    <div>
                        <AddSlipBtn />
                    </div>
                </div>
            );
        }
        return <section className={`${context.content.page}`}>
            <InvestmentCalculator enable={true} />
            <MobileCurrentOdds />
            <OddsTable columns={RCColumns} />
            {comingleMsg1}
            <OddsDropLegend />
            <PoolInvTable />
            {mtg.poolInvs!=null && isStartSell(mtg) ? comingleMsg2 : null}
            {betSlip}
            <InvestmentCalculator enable={!context.windowSize.isMobileSize} />
        </section>

    }

}